import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Layout from "./layout";
import { extendTheme } from "@chakra-ui/react";

export const wrapPageElement = ({ element }) => {
  const theme = extendTheme({
    colors: {
      tcpcyan: {
        50: "#ecf8f9",
        100: "#c5e9ed",
        200: "#9edae1",
        300: "#77ccd4",
        400: "#50bdc8",
        500: "#3db6c2",
        600: "#37a4af",
        700: "#2b7f88",
        800: "#1e5b61",
        900: "#12373a",
      },
    },
    fonts: { heading: "Montserrat", body: "Montserrat" },
  });
  return (
    <ChakraProvider theme={theme}>
      <Layout>{element}</Layout>
    </ChakraProvider>
  );
};
